import { Address } from "@models/Checkout/Checkout"

export const KenticoEmptyFields = {
  RichText: "<p><br></p>",
}
export const DefaultManufactureLaterAddress: Address = {
  unit: "16/Level 3",
  building: "",
  suburb: "Parnell",
  street: "125 The Strand",
  postCode: "1010",
  city: "AUCKLAND",
  fullAddress: "Unit 16, Level 3, 125 The Strand, Parnell, AUCKLAND 1010",
  isManualAddress: true,
}
export const OrderTypes = {
  New: "New",
  Redesign: "Redesign"
}
export const PlateSizes = {
  Regular: {
    name: "Regular",
    width: 360,
    height: 125,
  },
  Graphic: {
    name: "Graphic",
    width: 360,
    height: 155,
  },
  Slim: {
    name: "Slim",
    width: 360,
    height: 100,
  },
  European: {
    name: "European",
    width: 520,
    height: 112,
  },
  Motorbike: {
    name: "Motorbike",
    width: 260,
    height: 100,
  },
  Large: {
    name: "Large",
    width: 440,
    height: 112,
  },
  Japanese: {
    name: "Japanese",
    width: 330,
    height: 165
  },
  American: {
    name: "American",
    width: 305,
    height: 153
  }
}

export const ButtonTypes = {
  Primary: "primary",
  Secondary: "secondary",
  Alternate: "alternate",
  DarkModeSecondary: 'darkModeSecondary'
}

export const MessagePlateLocations = {
  TopAndBottom: "top_and_bottom",
  BottomOnly: "bottom_only",
  TopOnly: "top_only",
}
export const PlateSizeCodes = {
  Regular: 4,
  Graphic: 1,
  Slim: 2,
  European: 13,
  Motorbike: 3,
  Large: 14,
  Japanese: 15,
  American: 16,
}

export const PlateDesignFormats = {
  Fixed: "Fixed",
  Flexi: "Flexi",
  Premium3: "Premium3",
  Premium2: "Premium2",
}
export const DefaultCombination = {
  Car: "ABC123",
  Motorbike: "ABC12",
  Trailer: "ABC12",
}

export const PaymentOptions = {
  CreditCard: 1,
  Account2Account: 2,
  Afterpay: 3,
  KiwiplatesLayby: 4,
  DealerPaymentRequest: 5,
  Gem: 6
}

export const S3BucketBaseUrl =
  "https://kiwiplates-marketplace-photos.s3.ap-southeast-2.amazonaws.com"

export const S3BucketFileEditUrl =
  "https://s3-ap-southeast-2.amazonaws.com/kiwiplates-marketplace-photos"

export const S3BucketWebBaseUri =
  "http://kiwiplates-marketplace-photos.s3-website-ap-southeast-2.amazonaws.com"


export const FeatureFlag = {
  EnableEmailValidation: true
}

export const GA4Event = {
  AddToCart: "add_to_cart",
  ViewItem: "view_item",
  Purchase: "GA4_purchase",
  Search: "search",
  PlateSuggestions: "plate_suggestion"
}
export const VOSAFileSizeLimit = 2097152;

export const MininumDirverLicenseAge = 16;

export const ReCaptchaThreshold = 0.49;

export const NameRegex = /^[a-zA-Z'-.\s]+$/;

export const EmailRegex = /\S+@\S+\.\S+/;

export const PhoneRegex = /^\+?[\d\-\s]+$/;

export const PersonalDetailsSection = {
  OwnershipType: "ownership-type",
  Details: "details",
  Address: "address",
  Liscense: "liscense",
  VehicleRegistration: "vehicle-registration"
}

export const ReviewOrderSection = {
  PaymentOptions: "payment-options",
  OrderDetails: "order-details"
}

export const FullNameRegex = /^[a-zA-Z]([-']?[a-zA-Z]+)*( [a-zA-Z]([-']?[a-zA-Z]+)*)+$/;

export const KpiStatusMapping  = {
  Pending: 'Unpaid',
  Unpaid: 'Unpaid',
  InProgress: 'In Progress',
  Paid: 'Paid',
  Processed: 'Downloaded to Licensys',
  Manufactured: 'Order Completed',
  Cancelling: 'Cancelling',
  Approved: 'Paid',
  Authorised: 'Paid',
  Expired: 'Cancelled',
  Cancelled: 'Cancelled',
  PendingPaymentRequest: 'Unpaid',
  LayByPendingAuth: 'LayBy Pending Auth',
  LayByInProgress: 'LayBy In Progress',
  LayBySuspended: 'LayBy Suspended',
  LayByAuthorised: 'LayBy Authorised',
  Refund: 'Cancelled'
}

export const KpiDeliveryMapping  = {
  OnRequest: 'Dealer to Request',
  Individual: 'Plates Being Made',
  Company: 'Plates Being Made',
  PreManufacture: 'Plates Being Made',
  PreManufactureCompany: 'Plates Being Made'
}

export const BadgeTransactionType  = {
  All: 'All',
  New: 'NewOnly',
  Redesign: 'RedesignOnly'
}
