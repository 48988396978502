const getLaybyQuotesByAmount = (apiUrlBase: string, amount: number, promoCode?: string) => {
    return `${apiUrlBase}/basket/layby/quote/amount/all?amount=${amount}&promoCode=${promoCode}`
}

const getBasketIdUrl = (apiUrlBase: string) => {
    return `${apiUrlBase}/basket`;
}
const placeOrderUrl = (apiUrlBase: string, basketId: string, deliveryType: number, isLayby: boolean, autoPay: boolean, paymentFrequency: string, overrideNextPaymentDue: string) => {
    return `${apiUrlBase}/basket/placeorder/${basketId}?deliveryType=${deliveryType}&islayby=${isLayby}&autoPay=${autoPay}&paymentFrequency=${paymentFrequency}&overrideNextPaymentDue=${overrideNextPaymentDue}`;
}


const paymentOnlineRequestUrl = (apiUrlBase: string, basketId: string, method: number, amount?: number) => {
    return amount ?
        `${apiUrlBase}/basket/paymentrequest/${basketId}?method=${method}&amount=${amount}`
        :
        `${apiUrlBase}/basket/paymentrequest/${basketId}?method=${method}`;
}
const getPaymentFromAfterpayRequestUrl = (apiUrlBase: string, basketId: string, amount?: number) => {
    return `${apiUrlBase}/basket/paymentbnpl/${basketId}?amount=${amount}`
}
const getAfterpayAuthRequestUrl = (apiUrlBase: string, token: string) => {
    return `${apiUrlBase}/basket/paymentbnplauth/?token=${token}`;
}
const basketResponseUrl = (apiUrlBase: string, basketId: string) => {
    return `${apiUrlBase}/basket/${basketId}`;
}
const checkBasketResponseUrl = (apiUrlBase: string, basketId: string) => {
    return `${apiUrlBase}/basket/check/${basketId}`;
}
const removeBasketPromoUrl = (apiUrlBase: string, basketId: string) => {
    return `${apiUrlBase}/basket/removepromo/${basketId}`
}
const saveBasketLineItemUrl = (apiUrlBase: string, basketId: string) => {
    return `${apiUrlBase}/basket/line/${basketId}`
}
const deleteBasketLineItemUrl = (apiUrlBase: string, basketId: string, basketLineId: string) => {
    return `${apiUrlBase}/basket/line/${basketId}?lineId=${basketLineId}`;
}
const validatePromotionUrl = (apiUrlBase: string, promoCode: string, basketId: string) => {
    return `${apiUrlBase}/basket/validate/${promoCode}?basketId=${basketId}`
}
const checkPromotionUrl = (apiUrlBase: string, promoCode: string, basketId: string) => {
    return `${apiUrlBase}/basket/check/${promoCode}?basketId=${basketId}`
}
const checkDesignForPromotionUrl = (apiUrlBase: string, promoCode: string, basketId: string) => {
    return `${apiUrlBase}/basket/checkdesign/${promoCode}?basketId=${basketId}`
}
export const BasketApiUrls = {
    paymentOnlineRequestUrl: paymentOnlineRequestUrl,
    placeOrderUrl: placeOrderUrl,
    getBasketIdUrl: getBasketIdUrl,
    getLaybyQuotesByAmount: getLaybyQuotesByAmount,
    basketResponseUrl: basketResponseUrl,
    saveBasketLineItemUrl: saveBasketLineItemUrl,
    deleteBasketLineItemUrl: deleteBasketLineItemUrl,
    validatePromotionUrl: validatePromotionUrl,
    removeBasketPromoUrl: removeBasketPromoUrl,
    checkBasketResponseUrl: checkBasketResponseUrl,
    getPaymentFromAfterpayRequestUrl: getPaymentFromAfterpayRequestUrl,
    getAfterpayAuthRequestUrl: getAfterpayAuthRequestUrl,
    checkPromotionUrl: checkPromotionUrl,
    checkDesignForPromotionUrl: checkDesignForPromotionUrl
}